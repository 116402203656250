import { createReducer, on } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { PollState } from './poll-smart.model';
import { clearPoll, getPollSuccess, savePollSuccess, undoPollSuccess } from './poll-smart.actions';


export const pollInitialState: PollState = {
	pollData: undefined,
	error: undefined
};


export const pollsReducer = createReducer(
	pollInitialState,
	on(getPollSuccess, (state, { pollData }) => ({ ...state, pollData: { ...pollData } })),
  on(savePollSuccess, (state, { pollData }) => ({ ...state, pollData: { ...pollData } })),
  on(undoPollSuccess, (state, { pollData }) => ({ ...state, pollData: { ...pollData } })),
  on(clearPoll, () => ({ ...pollInitialState }))
);
