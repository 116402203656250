import {  pollsReducer } from './../domain/poll-smart/poll-smart.reducer';
import { CookieConsentState } from './cookie-consent/cookie-consent.model';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';
import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { EnvironmentService } from '../../environments/environment.service';
import { LogState } from '../domain/log/log.model';
import { logReducer } from '../domain/log/log.reducer';
import { UserState } from '../domain/user/user.model';
import { userReducer } from '../domain/user/user.reducer';
import { WidgetState } from '../domain/widget/widget.model';
import { widgetReducer } from '../domain/widget/widget.reducer';
import { AuthState } from './auth/auth.models';
import { authReducer } from './auth/auth.reducer';
import { RoutesStateUrl } from './router/routes.state';
import { SessionState } from './session/session.models';
import { sessionReducer } from './session/session.reducers';
import { cookieConsentReducer } from './cookie-consent/cookie-consent.reducer';
import { settingsReducer } from '../domain/settings/settings.reducer';
import { NewsletterAbbonamentoState } from '../domain/settings/settings.model';
import { TopicState } from '../domain/topic/topic.entity';
import { topicReducer } from '../domain/topic/topic.reducer';
import { DottnetTemplate } from '../domain/template-ct/template-ct.model';
import { templateCtReducer } from '../domain/template-ct/template-ct.reducer';
import { CityState } from '../domain/city/city.entity';
import { ProvinceState } from '../domain/province/province.entity';
import { CategoryState } from '../domain/category/category.entity';
import { SpecialtyState } from '../domain/specialty/specialty.entity';
import { cityReducer } from '../domain/city/city.reducer';
import { provinceReducer } from '../domain/province/province.reducer';
import { categoryReducer } from '../domain/category/category.reducer';
import { specialtyReducer } from '../domain/specialty/specialty.reducer';
import { ProfessionState } from '../domain/profession/profession.entity';
import { professionReducer } from '../domain/profession/profession.reducer';
import { InitState } from './init/init.models';
import { initReducer } from './init/init.reducer';
import { Poll, PollState } from '../domain/poll-smart/poll-smart.model';

export const USER_FEATURE_NAME: string = 'user';
export const WIDGET_FEATURE_NAME: string = 'widgets';
export const TOPIC_FEATURE_NAME: string = 'topic';
export const TEMPLATE_FEATURE_NAME: string = 'templatect';
export const CATEGORY_FEATURE_NAME: string = 'categories';
export const POLL_FEATURE_NAME: string = 'poll';
const environment = new EnvironmentService();

/*
export const metaReducers: MetaReducer<AppState>[] = [
  ];
if (!environment.production) {
	if (!environment.test) {
		metaReducers.unshift(metaReducerFactory());
	}
}
*/

export const selectTemplateCtState = createFeatureSelector<{
	templateCtMap: DottnetTemplate;
}>(TEMPLATE_FEATURE_NAME);


export const selectCategoryState = createFeatureSelector<CategoryState>(CATEGORY_FEATURE_NAME);

export const selectTopicState = createFeatureSelector<TopicState>(TOPIC_FEATURE_NAME);
export const selectAuthState = createFeatureSelector<AuthState>('auth');
export const selectInitState = createFeatureSelector<InitState>('config');

export const selectSessionState = createFeatureSelector<SessionState>('session');

export const selectRouterState =
	createFeatureSelector<RouterReducerState<RoutesStateUrl>>('routes');

export const selectUserState = createFeatureSelector<UserState>(USER_FEATURE_NAME);


export const selectPollState = createFeatureSelector<PollState>(POLL_FEATURE_NAME);


export const selectWidgetState = createFeatureSelector<WidgetState>(WIDGET_FEATURE_NAME);

export const selectLogState = createFeatureSelector<LogState>('logs');

export const selectCookieConsentState = createFeatureSelector<CookieConsentState>('cookieconsent');


export interface AppState {
	auth: AuthState;
	router: RouterReducerState<RoutesStateUrl>;
	session: SessionState;
	user: UserState;
	logs: LogState;
	widgets: WidgetState;
	cookieconsent: CookieConsentState;
	templatect: {
		templateCtMap: DottnetTemplate;
	};
	topic: TopicState;
	cities: CityState;
	provinces:ProvinceState;
	categories:CategoryState;
	specialties:SpecialtyState;
  professiontypes: ProfessionState;
  config: InitState;
  poll: PollState;
}


export const reducers: ActionReducerMap<AppState> = {
	auth: authReducer,
	router: routerReducer,
	session: sessionReducer,
	user: userReducer,
	logs: logReducer,
	widgets: widgetReducer,
	cookieconsent: cookieConsentReducer,
	templatect: templateCtReducer,
	topic: topicReducer,
	cities: cityReducer,
	provinces:provinceReducer,
	categories:categoryReducer,
	specialties:specialtyReducer,
  professiontypes: professionReducer,
  config: initReducer,
  poll: pollsReducer
};
