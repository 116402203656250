import { createAction, props } from '@ngrx/store';
import { ErrorMessage } from '../error/error-message.model';
import { Poll, PollQuestionAnswer } from './poll-smart.model';


export const getPoll = createAction('[Poll] getPoll',
	props<{ pollId: number, userId: number }>());


export const getPollSuccess = createAction(
	'[Poll] getPollSuccess',
	props<{ pollData: Poll }>()
);
export const getPollFailure = createAction(
	'[Poll] getPollFailure',
	props<{ error: ErrorMessage }>()
);


export const savePoll = createAction(
	'[Poll] savePoll',
	props<{ pollQuestionAnswer: PollQuestionAnswer[], userId: number, pollId: number }>()
);

export const savePollSuccess = createAction(
	'[Poll] savePollSuccess',
  props<{ pollData: Poll }>());

export const savePollFailure = createAction(
	'[Poll] savePollFailure',
	props<{ error: ErrorMessage }>()
);

export const undoPoll = createAction(
	'[Poll] undoPoll',
	props<{ userId: number, pollId: number }>()
);

export const undoPollSuccess = createAction(
	'[Poll] undoPollSuccess',
  props<{ pollData: Poll }>());

export const undoPollFailure = createAction(
	'[Poll] undoPollFailure',
	props<{ error: ErrorMessage }>()
);

export const clearPoll = createAction('[Poll]  clearPoll');

